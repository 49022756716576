<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card 
                    flat
                    >
                        <v-card-text>
                            <div class="form-box-title">{{ itemsTitle }}</div>
                            <template v-if="flatItems.length === 0">
                                {{ $t('Нет_доступных_элементов') }}
                            </template>
                            <template
                                v-else
                            >
                                <v-list class="modal-select-list" dense outlined>
                                    <v-list-item-group
                                        v-model="selectedItem"
                                        color="cyan darken-1"
                                    >                                        
                                        <v-list-item
                                            v-for="(item, i) in flatItems"
                                            :key="i"
                                            :value="item"
                                            @dblclick="doubleSelect(item)"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="$t(item.title)"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                    color="cyan" 
                    text 
                    depressed 
                    @click="save" 
                    v-if="selectedItem">
                        {{ $t("Создать") }}
                    </v-btn>

                    <v-btn 
                    color="blue-grey" 
                    text
                    depressed
                    @click="cancel">
                        {{ $t("Отмена") }}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import i18n from '@/i18n'
import { mapGetters } from 'vuex';

export default {
    name: "CreateCorrespondenceDocumentDialog",
    data () {
        return {
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            selectedItem: null,
            knownDocs: {
                Inner: { title: "Внутренний", params: { createType: "Documents.InnerDocument" }, requiredConfig: [8, 64] },
                InnerCommon: { title: "Общий_внутренний", params: { createType: "Documents.CommonInnerDocument", isCommon: true }, requiredConfig: [8] },
                Outgoing: { title: "Исходящий", params: { createType: "Documents.OutgoingDocument" }, requiredConfig: [8, 64] },
                OutgoingBypassingProject: { title: "Исходящий_без_возможности_отправки_получателям", params: { createType: "Documents.OutgoingDocument.BypassingProject" }, requiredConfig: [8, 64] },
                OutgoingCommon: { title: "Проект_общего_документа", params: { createType: "Documents.OutgoingDocument", isCommon: true }, requiredConfig: [8] },
                ORD: { title: "ОРД", params: {  createType: "Documents.ORD" }, requiredConfig: [8] },
                Incoming: { title: "Входящий", params: { createType: "Chancellery.IncomingDocument" }, requiredConfig: [8, 64] },
                //NPA: { title: i18n.t("Нормативно-правовой_акт"), params: { createType: "Documents.NPA.Document", isCommon: true } },
            },
        }
    },
    computed: {
        ...mapGetters('global/auth', ['getUserInfo', 'isCommonServiceEnabled']),
        canCreateDocs() {
            let result = this.getUserInfo.Permissions.filter(x => x.startsWith("Create")).map(x => x.replace("Create", ""));

            if (this.getUserInfo.Permissions.includes("ChancelleryOutcomingDocumentsList") && this.getUserInfo.Permissions.includes("CanSignChancellery"))
                result.push("OutgoingBypassingProject");

            if (result.includes('Outgoing') && this.isCommonServiceEnabled)
                result.push("OutgoingCommon");
            if (result.includes('Inner') && this.isCommonServiceEnabled)
                result.push("InnerCommon");

            return result.filter(x => x.toLowerCase() != "resolution");
        },
        title() {
            return this.$t("Создание_документа");
        },
        itemsTitle() {
            return this.$t("Выберите_тип_документа");
        },
        flatItems() {
            let items = [];
            
            this.canCreateDocs.forEach((item) => {
                if (this.knownDocs[item] && this.$validatePermission(this.knownDocs[item])) {

                    if (Array.isArray(this.knownDocs[item]))
                        this.knownDocs[item].forEach(item => items.push(item));
                    else
                        items.push(this.knownDocs[item]);
                }
            });
            return this.$_(items).orderBy(['title'], ['asc']).value();
        }
    },
    methods: {
        async open(options = {})
        {
            this.visible = true;
            this.options = Object.assign(this.options, options);

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });
        },
        save() {
            this.visible = false;
            this.resolve(this.selectedItem);
        },
        doubleSelect(item) {
            this.visible = false;
            this.resolve(item);
        }
    }
}
</script>